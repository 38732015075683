// Dependencies
import React from "react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
import useMicrocopy from "../hooks/useMicrocopy";
import { EN, JP } from "../constants/locales"
// Components
import LocaleLink from "./LocaleLink"
import { Logo } from "./Icons"
import Markdown from "./Markdown"
import RichText from "./RichText"

const LocaleToggle = ({ locale }) => {

	const location = useLocation()
	const to = locale === EN ? location.pathname.replace(EN, JP) : locale === JP ? location.pathname.replace(JP, EN) : location.pathname
	
	return (
		<Link to={to} className={`locale-toggle`}>
			<span className={locale === EN ? 'active' : ''}>EN</span>
			<span className={locale === JP ? 'active' : ''}>JP</span>
		</Link>
	)
}

export const MenuButtons = ({ open, handleClick, locale }) => {

  return (
    <div className={`menu-buttons ${open ? 'is-open' : ''}`} >
			<button className={`menu-button`} onClick={handleClick}>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="8" cy="8" r="4.5" stroke={open ? "#FFFFFF" : "#0B0B0A"} fill={open ? 'white' : 'none'} />
				</svg>
				<span className="menu-button__label">{open ? 'CLOSE' : 'MENU'}</span>
			</button>

			{/* <LocaleToggle locale={locale} /> */}
      {/* Commit again */}
    </div>
  )
}

export const HeaderContents = ({ headerTitles, handleMenuClick, id, locale }) => {

  const { manifesto, tagline } = useSettings(locale);
	const { musicity } = useMicrocopy(locale)
	const checkTitles = headerTitles && typeof headerTitles === 'object' && headerTitles.raw

  return (
    <div className="header__contents">
      <div className="name">
        {/* <div onClick={handleMenuClick}><strong>MENU</strong></div> */}
      </div>
      <div className="info">
        <div className="info__title--mobile">
          <strong>{musicity}</strong> <br />
          <Markdown field={tagline} />
					{/* <p><LocaleToggle locale={locale} /></p> */}
        </div>
				<div className="info__title--desktop">
					<strong>{musicity}</strong> <br />
					<Markdown field={tagline} />
					{/* <p><LocaleToggle locale={locale} /></p> */}
					{/* <p>
						<Link to={`/`} locale={EN}>EN</Link>
						<span>{"|"}</span>
						<Link to={`/`} locale={JP}>JP</Link>
					</p> */}
				</div>
        
      </div>
      {!headerTitles ? (
        <div className="manifesto p3">
          <RichText content={manifesto} locale={locale} />
        </div>
      ) : (
        <div className="page-heading p1">
					{checkTitles ? (
						<RichText content={headerTitles} locale={locale} />
					) : (
						headerTitles()
					)}
        </div>
      )}
      <div className="logo">
        <LocaleLink to="/" locale={locale}>
          <Logo />
        </LocaleLink>
      </div>
    </div>
  )
}

const Header = (props) => {

  return (
    <header id="header">
      <HeaderContents {...props} />
    </header>
  )
}

export default Header
